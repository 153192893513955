import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Otp from "./pages/Otp";
import "./styles/main.scss";
import CheckIn from "./pages/Checkin";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Share from "./pages/Share";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Otp />,
  },
  {
    path: "/checkin/:code",
    element: <CheckIn />,
  },
  {
    path: "/share/:code",
    element: <Share />,
  },
]);

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <GoogleReCaptchaProvider
          reCaptchaKey={
            process.env.RECAPTCHA_SITE_KEY ||
            "6LfB5_IbAAAAAMCtsjEHEHKqcB9iQocwwxTiihJu"
          }
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          <RouterProvider router={router} />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  );
}

export default App;
