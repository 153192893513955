import { Checkbox, Form, Input, message } from "antd";
import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import sever from "../api";
import banner from "../assets/images/banner.png";
import logo from "../assets/images/logo.png";
import opt_succes from "../assets/images/opt_succes.png";
import pls_enter from "../assets/images/pls_enter.png";
import "../styles/otp.scss";

const Otp = () => {
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [hideForm, setHideForm] = React.useState(false);
  const handleFinish = (value: any) => {
    if (!executeRecaptcha) {
      message.error("Recaptcha không khả dụng");
      return;
    }
    executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
      sever
        .post("/otp", { ...value, reCaptchaToken: gReCaptchaToken })
        .then((res) => {
          if (res.data.otp > 10) {
            message.success(
              "Gửi OTP thành công. Otp của bạn là " + res.data.otp
            );
          }
          setHideForm(true);
          form.resetFields();
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response.data); // => the response payload
            message.error(err.response.data.message);
            return;
          }
          message.error("Gửi OTP thất bại! " + err.message);
        });
    });
  };
  return (
    <div className="otp">
      <div className="wrap-otp">
        <img src={logo} alt="" className="logo" />
        <img src={banner} alt="" className="banner" />

        {hideForm ? (
          <div className="title">
            <img src={opt_succes} alt="" />
          </div>
        ) : (
          <div className="form">
            <div className="title">
              <img src={pls_enter} alt="" />
            </div>
            <Form onFinish={handleFinish} form={form}>
              <Form.Item
                name={"name"}
                rules={[{ required: true, message: "Vui lòng nhập tên" }]}
              >
                <Input placeholder="TÊN" />
              </Form.Item>
              <Form.Item
                name={"phone"}
                rules={[
                  { required: true, message: "Vui lòng nhập số điện thoại" },
                  {
                    pattern: /^(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b$/,
                    message: "Số điện thoại không đúng định dạng",
                  },
                ]}
              >
                <Input placeholder="SỐ ĐT" />
              </Form.Item>
              <Form.Item
                name={"email"}
                rules={[
                  {
                    type: "email",
                    message: "Email không đúng định dạng",
                  },
                ]}
              >
                <Input placeholder="EMAIL" />
              </Form.Item>
              <Form.Item
                name={"check"}
                valuePropName="checked"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Vui lòng, đồng ý với chính sách bảo mật của chúng tôi"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Checkbox className="check_color">
                  <div className="check_color_text">
                    Tôi đã đọc và đồng ý với chính sách quyền riêng tư
                  </div>
                </Checkbox>
              </Form.Item>
              <div className="btn_cus" onClick={() => form.submit()}>
                GỬI
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Otp;
