import { Button, Spin, Typography, message } from "antd";
import axios from "axios";
import React from "react";
import { Download } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import sever from "../api";
import { useCopyToClipboard } from "../assets/hooks/useCopyToClipboard";
import FacebookIcon from "../assets/icon/Facebook";
import error from "../assets/images/error.jpg";
import hastag from "../assets/images/hastag.png";
import logo from "../assets/images/logo.png";
import bg_mobile from "../assets/images/bg-mobile.png";
import bg_desktop from "../assets/images/bg-img.png";
import "../styles/otp.scss";

const hashtag = "#viengiatomomatic3trong1tienloi";

const CheckIn = () => {
  const [image, setImage] = React.useState<string | undefined>("");
  const [customer, setCustomer] = React.useState<any>({});
  const navigate = useNavigate();
  const param = useParams();
  const { copyToClipboard, resetCopyStatus } = useCopyToClipboard();
  React.useEffect(() => {
    sever
      .get("/image/" + param.code)
      .then((res) => {
        setImage(res.data.avatar);
        setCustomer(res.data);
      })
      .catch((err) => {
        setImage(undefined);
        message.error(err.message);
      });
  }, [param.code]);
  return (
    <div className="otp">
      {image === "" ? (
        <Spin />
      ) : (
        <div className="wrap-otp">
          <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={() => {
              navigate("/");
            }}
          />
          <div className="title-checkin">
            Tải xuống khoảnh khắc chiến thắng <br /> của bạn và/hoặc chia sẻ
            <br /> ảnh của bạn qua FB.
          </div>
          <div className="img-main">
            <img
              src={image == null ? error : image}
              alt=""
              className="avatar"
            />
            <img
              src={window.innerWidth < 768 ? bg_mobile : bg_desktop}
              alt="banner"
              className="bg-img"
            />
          </div>
          {image ? (
            <>
              <div className="title">
                <img src={hastag} alt="" />
              </div>
              <div className="hashtag">
                <Typography.Paragraph ellipsis className="name">
                  {hashtag}
                </Typography.Paragraph>
                <div
                  className="btn_cus"
                  onClick={() => {
                    copyToClipboard(hashtag);
                    setTimeout(resetCopyStatus, 2000);
                  }}
                >
                  Sao chép
                </div>
              </div>
              <div className="btn-checkIn">
                <Button
                  icon={<Download width={"1.2em"} height={"1.2em"} />}
                  onClick={() => {
                    sever
                      .put("/image/" + param.code + "/download")
                      .finally(async () => {
                        const img = await axios.get(image, {
                          responseType: "blob",
                        });

                        const name = image.split("/");
                        const url = window.URL.createObjectURL(img.data);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          `${name[name.length - 1]}`
                        ); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                      });
                  }}
                >
                  Tải xuống
                </Button>
                <Button
                  icon={<FacebookIcon />}
                  onClick={() => {
                    sever
                      .put("/image/" + param.code + "/share")
                      .finally(async () => {
                        const url = window.location.href;
                        const newUrl = url.replace(/\/checkin/, "/share");
                        const img = await axios.get(image, {
                          responseType: "blob",
                        });
                        const data = {
                          title: "OMO MATIC - Viên giặt tiện lợi",
                          text: `${
                            customer?.customer?.name || ""
                          } đã tham gia chương trình "OMO MATIC - Viên giặt tiện lợi" và đạt được ${
                            customer.score
                          } điểm.`,
                          url: newUrl,
                          files: [
                            new File([img.data], "image.png", {
                              type: "image/png",
                            }),
                          ],
                        };
                        if (navigator.share && navigator.canShare(data)) {
                          navigator
                            .share(data)
                            .then(() => {
                              console.log("Successfully shared");
                            })
                            .catch((error) => {
                              console.error("Something went wrong", error);
                            });
                        } else {
                          window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${url}`,
                            "Facebook Share",
                            "width=600, height=400, scrollbars=no"
                          );
                        }
                      });
                  }}
                >
                  Chia sẻ
                </Button>
              </div>
            </>
          ) : (
            <div className="not-found">Không tìm thấy</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckIn;
