import axios from "axios";

const sever = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL ||
    "/api",
  withCredentials: false,
});

export default sever;
