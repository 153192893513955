import React from "react";

import Icon from "@ant-design/icons";

const facebookIconSvg = () => (
  <svg
    width={'1.2em'}
    height={'1.2em'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 49.26"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="Logo">
        <path
          id="Initiator"
          fill="currentColor"
          d="m50,24.63C50,11.03,38.81,0,25,0S0,11.03,0,24.63c0,11.55,8.07,21.24,18.96,23.91v-16.38h-5.16v-7.53h5.16v-3.24c0-8.38,3.85-12.27,12.2-12.27,1.58,0,4.32.31,5.43.61v6.82c-.59-.06-1.61-.09-2.89-.09-4.1,0-5.68,1.53-5.68,5.51v2.66h8.17l-1.4,7.53h-6.76v16.92c12.38-1.47,21.97-11.86,21.97-24.45Z"
        />
        <path
          id="F"
          fill="none"
          d="m34.79,32.16l1.4-7.53h-8.17v-2.66c0-3.98,1.58-5.51,5.68-5.51,1.27,0,2.3.03,2.89.09v-6.82c-1.12-.31-3.85-.61-5.43-.61-8.35,0-12.2,3.89-12.2,12.27v3.24h-5.16v7.53h5.16v16.38c1.93.47,3.96.73,6.04.73,1.03,0,2.04-.06,3.03-.18v-16.92h6.76Z"
        />
      </g>
    </g>
  </svg>
);
const FacebookIcon = (props: any) => (
  <Icon component={facebookIconSvg} {...props} />
);
export default FacebookIcon;
