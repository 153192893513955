import { Spin, message } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import sever from "../api";
import bg_mobile from "../assets/images/bg-mobile.png";
import bg_desktop from "../assets/images/bg-img.png";
import error from "../assets/images/error.jpg";
import fb from "../assets/images/fb.png";
import downIcon from "../assets/images/icon-down.png";
import imgShare from "../assets/images/img-share.png";
import lazada from "../assets/images/lazada.png";
import logo from "../assets/images/logo.png";
import shoppe from "../assets/images/shopee.png";
import textFooter from "../assets/images/text-footer.png";
import ytb from "../assets/images/ytb.png";
import img_title_share from "../assets/images/img-title-share.png";
import "../styles/share.scss";

const linkShoppe =
  process.env.REACT_APP_LINK_SHOPPE ||
  "https://shopee.vn/Combo-3-t%C3%BAi-vi%C3%AAn-gi%E1%BA%B7t-ti%E1%BB%87n-l%E1%BB%A3i-OMO-3-trong-1-(30-vi%C3%AAn-t%C3%BAi)-i.26947756.23755433906?sp_atk=2c0f4d80-72b8-45e7-95ce-a7a6d2dcbbdf&xptdk=2c0f4d80-72b8-45e7-95ce-a7a6d2dcbbd ";
const linkLazada =
  process.env.REACT_APP_LINK_LAZADA ||
  "https://www.lazada.vn/products/combo-3-tui-vien-giat-tien-loi-omo-3-trong-1-huong-tinh-te-30-vientui-i2423534344.html?spm=a2o4n.searchlist.list.7.351a6ff7VDZgMn";

const linkYtb =
  process.env.REACT_APP_LINK_YTB ||
  "https://www.youtube.com/channel/UCY8_xLO3NE3CayDLmb4jMig";

const linkFb =
  process.env.REACT_APP_LINK_FB || "https://www.facebook.com/OMOVietnam";

const Share = () => {
  const [data, setData] = React.useState<any>({});
  const navigate = useNavigate();
  const param = useParams();

  React.useEffect(() => {
    sever
      .get("/image/" + param.code)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, [param.code]);

  return (
    <div className="share">
      {data?.avatar === "" ? (
        <Spin />
      ) : (
        <>
          <div className="wrap">
            <img
              src={logo}
              alt="logo"
              className="logo"
              onClick={() => {
                navigate("/");
              }}
            />
            <div className="title-share">
              <img src={img_title_share} alt="" />
            </div>
            <div className="img-main">
              <img
                src={data?.avatar == null ? error : data?.avatar}
                alt=""
                className="avatar"
              />
              <img
                src={window.innerWidth < 768 ? bg_mobile : bg_desktop}
                alt="banner"
                className="bg-img"
              />
            </div>
            <div className="desc">
              <span className="flex">
                <span className="!text-[#0df8f9]">{data?.customer?.name}</span>
                &nbsp;
                {`đã tham gia trải nghiệm`}
              </span>
              <span>Viên Giặt OMO Matic và đạt được</span>
              <span className="flex">
                <span className="!text-[#0df8f9]">{data?.score}</span> &nbsp;
                {`điểm.`}
              </span>
            </div>
            <div className="desc">
              <span>Lướt xuống phía dưới để</span>
              <span>khám phá giải pháp giặt giũ tiện lợi mới</span>
              <span>Viên Giặt OMO Matic</span>
            </div>
            <img src={downIcon} alt="" className="down" />
            <img src={imgShare} alt="" className="img-share" />
          </div>
          <div className="footer">
            <img src={textFooter} alt="" className="text-img" />
            <div className="flex items-center justify-center gap-16 my-10">
              <img
                src={shoppe}
                alt="shoppe"
                className="rounded-[8px] w-[6rem] h-[6rem] cursor-pointer"
                onClick={() => window.open(linkShoppe, "_blank")}
              />
              <img
                src={lazada}
                alt="lazada"
                className="rounded-[8px] w-[6rem] h-[6rem] cursor-pointer"
                onClick={() => window.open(linkLazada, "_blank")}
              />
            </div>
            <div className="text-social">OMO Mạng xã hội</div>
            <div className="flex items-center justify-center gap-16 mt-8">
              <img
                src={fb}
                alt="fb"
                className="w-[4rem] h-[4rem] cursor-pointer"
                onClick={() => window.open(linkFb, "_blank")}
              />
              <img
                src={ytb}
                alt="ytb"
                className="w-[5rem] h-[4rem] cursor-pointer"
                onClick={() => window.open(linkYtb, "_blank")}
              />
            </div>
            <div className="unilever">
              <span>Unilever Việt Nam</span>
              <span>© Unilever 2023</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Share;
